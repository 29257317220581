<template>
  <div>
    <b-container>
      <b-row v-if="!emailLocked">
        <b-col cols="auto" class="mx-auto">
          <div
            class="logo-container"
            :data-version="appVersion"
            :class="{'with-version': appVersion != ''}"
          >
            <img src="images/iq-logo.svg" />
          </div>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col cols="auto" class="mx-auto">
          <div class="login-envelope-container">
            <b-icon icon="envelope" scale="2" variant="white" />
          </div>
        </b-col>
      </b-row>
      <b-row v-if="!emailLocked">
        <b-col cols="auto" class="mx-auto my-4 text-center">
          <p class="h5 text-lightgreen mb-0">For Business Leads & Company Contacts</p>
          <p>Intelligence to identify, engage, and close deals.</p>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col cols="auto" class="mx-auto my-4 text-center">
          <p class="h5 text-lightgreen mb-0">Check your email</p>
          <p class="text-muted">You will receive an access code to login.</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4" offset-md="4" class="mb-5">
          <b-form @submit="onSubmit">
            <b-form-group
              label="Email address:"
              :disabled="loginRequested"
              v-if="!emailLocked"
            >
              <b-form-input
                v-model="form.email"
                type="email"
                placeholder="Enter email"
                required
              ></b-form-input>
            </b-form-group>

            <!--
            <b-form-group
              label="Password:"
              description="Password for access is: Saferack1!"
            >
              <b-form-input
                type="password"
                placeholder="Your password"
                v-model="form.password"
                required
              ></b-form-input>
            </b-form-group>
            -->

            <div
              class="text-center"
              v-if="emailLocked"
            >
              <p class="text-muted">Enter Access Code</p>
              <PincodeInput
                v-model="form.access_code"
                :length="6"
              />
            </div>

            <div class="text-center">
              <template
                v-if="processing"
              >
                <b-button type="submit" class="px-5 py-2" variant="lightgreen" disabled>
                  <b-spinner small></b-spinner>
                  <span class="sr-only">Loading...</span>
                </b-button>
              </template>
              <template
                v-else-if="!emailLocked"
              >
                <b-button type="submit" class="px-5 py-2" variant="lightgreen" :disabled="hasError()">Next</b-button>
              </template>
              <template
                v-else
              >
                <b-button type="submit" class="px-5 py-2" variant="lightgreen" :disabled="hasError()">Login</b-button>
              </template>
            </div>

            <div class="text-center mt-4 text-email-hint" v-if="emailLocked">
              <small class="mb-0"><strong>Didn't receive the email?</strong></small>
              <br/>
              <small class="text-muted">Search your email for "ProspectorIQ Access Code"</small>
            </div>
          </b-form>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="auto" class="mx-auto text-center mt-5 footer-section">
          <img src="images/footer-logo.png" width="160" />
          <p class="mt-3 mb-5">Contact <a href="mailto:tmikulec@red7agency.com"><u>Tracy Mikulec</u></a> for more information about this application or notifications</p>
        </b-col>
      </b-row>
    </b-container>
    <b-overlay :show="authLoading" no-wrap />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PincodeInput from 'vue-pincode-input'

export default {
  name: 'Login',
  components: {
    PincodeInput,
  },
  data() {
    return {
      form: {
        email: '',
        password: '',
        access_code: '',
        error: {
          email: true,
          // password: true
        }
      },
      loginRequested: false,
      emailLocked: false,
      processing: false,
      year: new Date().getFullYear(),
    }
  },
  watch: {
    form: {
      handler() {
        this.validate()
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters('auth', {
      'authError': 'error',
      'authLoading': 'loading',
    }),
    ...mapGetters([
      'appVersion',
    ]),
  },
  methods: {
    onSubmit(e) {
      e.preventDefault()
      if (!this.hasError() && !this.authLoading) {
        // this.$store.dispatch('auth/login', {
        //   email: this.form.email,
        //   password: this.form.password,
        // })
        // .then(() => {
        //   this.$bvToast.toast('You are successfully logged in.', {
        //     variant: 'info',
        //     title: 'Login',
        //   })
        //   this.$router.push('/')
        // })
        // .catch((e) => {
        //   if (e.status == 404) {
        //     this.$bvToast.toast('No user exists with entered email', {
        //       variant: 'danger',
        //       title: 'Login',
        //     })
        //   } else if (e.status == 401) {
        //     this.$bvToast.toast('Email or Password does not match.', {
        //       variant: 'danger',
        //       title: 'Login',
        //     })
        //   } else {
        //     console.log(e)
        //   }
        // })
        if (!this.emailLocked) {
          this.processing = true
          this.loginRequested = true
          this.$store.dispatch('auth/loginRequest', {
            email: this.form.email,
          })
          .then(() => {
            this.emailLocked = true
            this.$bvToast.toast('Please check your inbox to get access code.', {
              variant: 'info',
              title: 'Login',
            })
            this.form.error.access_code = true
          })
          .catch((e) => {
            if (e.status == 404) {
              this.$bvToast.toast('No user exists with entered email', {
                variant: 'danger',
                title: 'Login',
              })
            } else {
              console.log(e)
            }
            this.loginRequested = false
          })
          .finally(() => {
            this.processing = false
          })
        } else {
          this.processing = true
          this.$store.dispatch('auth/loginWithCode', {
            email: this.form.email,
            access_code: this.form.access_code,
          })
          .then(() => {
            this.$bvToast.toast('You are successfully logged in.', {
              variant: 'info',
              title: 'Login',
            })
            this.$router.push('/')
          })
          .catch((e) => {
            if (e.status == 404) {
              this.$bvToast.toast('No user exists with entered email', {
                variant: 'danger',
                title: 'Login',
              })
            } else if (e.status == 401) {
              this.$bvToast.toast('Access Code does not match.', {
                variant: 'danger',
                title: 'Login',
              })
            } else {
              console.log(e)
            }
            this.processing = false
          })
        }
      }
    },
    validate() {
      this.form.error.email = !this.form.email
      // this.form.error.password = !this.form.password
      if (this.emailLocked) {
        this.form.error.access_code = this.form.access_code.length != 6
      }
    },
    hasError() {
      return Object.keys(this.form.error).some((key) => this.form.error[key])
    },
  }
}
</script>